@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Personal Info */
/* ---------------------------------------------------------------- */

.myAxelosSignIn {
  display: flex;
  flex-grow: 1;

  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1600px;
  padding: 0 96px;

  @media only screen and (min-width: 768px) and (max-width: 1536px) {
    min-width: 1370px;
  }

  .backgroundBrandingContainer {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    position: relative;
    width: 42.5%;

    .image {
      background-color: #282d3d;
      background-image: url('/images/artwork/bg-signin.jpg');
      background-position: 100% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      // left: calc(-50vw - -704px);
      left: -120px;
      position: absolute;
      right: 0;
      top: 0;
    }

    .register {
      display: flex;
      flex-direction: column;
      max-width: 360px;
      padding-right: 96px;
      position: relative;
      width: 100%;

      > h1 {
        color: #fff;
        font-family: Montserrat, sans-serif;
        font-size: 32px;
        line-height: 39px;
        margin: -8px 0;

        span {
          color: #6de2e2;
        }
      }

      > svg {
        height: 59px;
        margin: 48px 0 64px;
        width: 222px;
      }

      .btnRegister,
      .buttons button {
        background-color: transparent;
        border-color: #fff;
        color: #fff;
        width: 266px;
        align-items: center;
        border: 1px solid #fff;
        justify-content: center;
        font-family: Open Sans, sans-serif;
        padding: 0 48px;
        height: 64px;
        line-height: normal;
        border-radius: 11px;

        -webkit-touch-callout: none;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-flex;
        flex-shrink: 0;
        font-size: 19px;
        position: relative;
        touch-action: manipulation;
        transition-duration: 0.175s;
        transition-property: background-color, color;
        transition-timing-function: ease-in-out;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        vertical-align: top;
        white-space: nowrap;
      }
    }

    .backgroundSlice {
      bottom: auto;
      right: -40px;
      top: 0;

      background-image: url('/images/artwork/bg-slice.svg');
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      height: 600px;
      max-width: 303px;
      pointer-events: none;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 2;
    }
  }

  .myAxelosForm {
    align-items: center;
    background-color: #f9f9f9;
    box-sizing: border-box;
    display: flex;
    flex: 1;

    .formContainer {
      margin: 0 0 0 auto;
      max-width: 576px;
      position: relative;
      width: 100%;

      > h2 {
        color: #282d3d;
        font-size: 32px;
        line-height: 39px;
        margin: -7px 0;
        text-align: center;
      }

      .signInForm {
        // max-width: 300px;
        // margin: 0 auto;
        // margin-top: 20px;
        // border: 2px solid #ebebed;
        padding: 30px;

        > .question {
          margin: 5px 0 24px;
        }

        > h2 {
          margin-bottom: 30px;
        }

        .error {
          color: red;
          word-break: break-word;
        }

        /* -------------------------------------------------------------- */
        /* Form */
        /* -------------------------------------------------------------- */
        > form {
          /* ---------------------------------------------------------- */
          /* Input */
          /* ---------------------------------------------------------- */
          input {
            border-radius: 9px;
            @media (max-width: 640px) {
              border-radius: 4px;
            }

            /* ------------------------------------------------------------ */
            /* Button Row */
            /* ------------------------------------------------------------ */
            > div:last-child {
              margin-top: 0;
              /* ---------------------------------------------------------- */
              /* Button */
              /* ---------------------------------------------------------- */
              button {
                align-self: flex-end;
                min-width: 200px;
              }
            }
          }

          .buttons {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 29px;
            position: relative;

            :hover {
              color: #ce2d4f;
            }
          }

          .next {
            color: #282d3d;
            font-weight: 700;
            width: 266px;
            margin-bottom: 10px;
          }

          .forgotPassword {
            color: #282d3d;
            cursor: pointer;
            display: none;
            font-family: Open Sans, sans-serif;
            font-size: 17px;
            line-height: 22px;
            margin-top: -5px;
            position: absolute;
            text-decoration: none;
            top: calc(100% + 16px);
            transition-duration: 0.175s;
            transition-property: opacity, color;
            transition-timing-function: ease-in-out;
          }
        }
      }
    }
  }
}
