@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Email Form */
/* ---------------------------------------------------------------- */
.cpdForm {
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  fieldset > p {
    font-size: 17px;
    line-height: 25px;
    margin: -6px 0;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Label Focus Override */
  /* ---------------------------------------------------------------- */
  label:focus {
    text-decoration: none;
  }
  /* ---------------------------------------------------------------- */
  /* Textarea */
  /* ---------------------------------------------------------------- */
  textarea {
    height: 158px;
    @media (max-width: 1536px) {
      height: 148px;
    }
    @media (max-width: 1280px) {
      height: 136px;
    }
    @media (max-width: 1024px) {
      height: 126px;
    }
    @media (max-width: 767px) {
      height: 122px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Buttons Container */
  /* ---------------------------------------------------------------- */
  .buttons {
    @media (max-width: 767px) {
      position: relative;
    }
    /* ---------------------------------------------------------------- */
    /* Loading Spinner */
    /* ---------------------------------------------------------------- */
    .spinner {
      background-color: $brandBlackGrape;
      background-image: url('/images/icons/button-loading.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 64px 64px;
      border-radius: 50%;
      display: block;
      flex-shrink: 0;
      height: 72px;
      margin-right: 24px;
      top: 0;
      width: 72px;
      @media (max-width: 1536px) {
        background-size: 56px 56px;
        height: 64px;
        margin-right: 20px;
        width: 64px;
      }
      @media (max-width: 1280px) {
        background-size: 48px 48px;
        height: 56px;
        margin-right: 16px;
        width: 56px;
      }
      @media (max-width: 767px) {
        background-size: 40px 40px;
        height: 48px;
        margin-right: 12px;
        width: 48px;
      }
      @media (max-width: 520px) {
        background-size: 32px 32px;
        height: 40px;
        margin: 28px 0 0;
        position: absolute;
        right: calc(25% - 3px);
        transform: translateX(50%);
        width: 40px;
        z-index: 1;
        ~ button:last-child {
          span {
            color: transparent;
          }
        }
      }
      @media (max-width: 375px) {
        margin-top: 20px;
      }
    }
  }
}
