/* -------------------------------------------------------------- */
/* Font Family */
/* -------------------------------------------------------------- */
$primary: 'Open Sans', sans-serif;
$secondary: 'Montserrat', sans-serif;
/* -------------------------------------------------------------- */
/* Font Weight */
/* -------------------------------------------------------------- */
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
/* -------------------------------------------------------------- */
/* Brand Colours */
/* -------------------------------------------------------------- */
$brandAqua: #6de2e2;
$brandAquaDark: darken($brandAqua, 10%);
$brandAquaLight: #8ae8e8;

$brandAquaPastel: #d3f6f6;
$brandAquaDarkPastel: #c6f4f4;
$brandAquaLightPastel: #dcf8f8;
$brandAquaXLightPastel: #edfcfc;

$brandBerry: #ce2d4f;
$brandBerryDark: darken($brandBerry, 10%);
$brandBerryLight: #d75772;

$brandBlackGrape: #282d3d;
$brandBlackGrapeDark: #191c26;
$brandBlackGrapeLight: #3c414f;
$brandGreyLight: #f0f0f0;
/* -------------------------------------------------------------- */
/* Product Colours */
/* -------------------------------------------------------------- */
$productItil: #8f63e5;
$productItilDark: #6c5293;
$productItilLight: #c7a4f2;
$productItilTint: #a582ea;

$productMaturity: #2274a5;
$productMaturityDark: #0e3460;
$productMaturityLight: #86c7db;
$productMaturityTint: #5490b7;

$productProPath: #2ebfa5;
$productProPathDark: #3a8f7f;
$productProPathLight: #60dfd0;
$productProPathTint: #64ccb7;

$productTransformation: #f25388;
$productTransformationDark: #aa4f74;
$productTransformationLight: #f783b6;
$productTransformationTint: #f678a0;

$productResilia: #ae1d57;
$productResiliaDark: #580f2b;
$productResiliaLight: #c05078;
$productResiliaTint: #c05078;
/* -------------------------------------------------------------- */
/* Monochrome */
/* -------------------------------------------------------------- */
$mono5: #4f5360;
$mono4: #767983;
$mono3: #9d9fa6;
$mono2: #c4c5ca;
$mono1: #ebebed;
/* -------------------------------------------------------------- */
/* Shades */
/* -------------------------------------------------------------- */
$white: #fff;
$greyXXLight: #f2f2f3;
$greyXLight: #e9e9eb;
$greyLight: #e8e9eb;
$grey: #ddd;
$greyDark: #d7d7db;
$black: #000;
/* -------------------------------------------------------------- */
/* Page Background */
/* -------------------------------------------------------------- */
$pageBackground: #f9f9f9;
