@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Header */
/* -------------------------------------------------------------- */
.header {
  background-color: $white;
  box-shadow: 0 0 6px rgba($black, 0.15);
  display: block;
  flex-shrink: 0;
  height: 96px;
  position: relative;
  width: 100%;
  z-index: 99;
  @media (max-width: 1280px) {
    height: 88px;
  }
  @media (max-width: 767px) {
    height: 72px;
  }
  /* -------------------------------------------------------------- */
  /* Container */
  /* -------------------------------------------------------------- */
  > div {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    z-index: 2;
  }
  /* ---------------------------------------------------------------- */
  /* Bookmarks Link */
  /* ---------------------------------------------------------------- */
  .bookmark {
    margin-right: 20px;
    @media (max-width: 1280px) and (min-width: 897px), (max-width: 375px) {
      margin-right: 16px;
    }
    @media (max-width: 896px) and (min-width: 768px) {
      margin-right: 12px;
    }
    @media (max-width: 767px) {
      margin-left: auto;
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      height: 17px;
      width: 12px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Sign In Button */
  /* ---------------------------------------------------------------- */
  .signIn {
    height: 48px;
    margin-left: auto;
    padding: 0 32px;
    @media (max-width: 1024px) {
      height: 44px;
      padding: 0 24px;
    }
    @media (max-width: 767px) {
      height: 40px;
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    span {
      font-size: 16px;
      font-weight: $semibold;
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      height: 15px;
      margin-left: 31px;
      width: 9px;
      path {
        fill: $brandBlackGrape;
      }
    }
  }
  &.menuAnimating {
    &::before {
      background-color: rgba($black, 0.5);
    }
  }
}
