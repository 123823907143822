@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Payment History Year Item */
/* ---------------------------------------------------------------- */
.paymentHistoryYearItem {
  background-color: $mono1;
  border-radius: 9px;
  box-sizing: border-box;
  color: $brandBlackGrape;
  display: block;
  font-weight: normal;
  height: auto;
  letter-spacing: normal;
  line-height: normal;
  margin: 24px 0 0;
  width: 100%;
  @include no-user-select;
  @media (max-width: 1536px) {
    margin-top: 20px;
  }
  @media (max-width: 1280px) {
    margin-top: 16px;
  }
  @media (max-width: 640px) {
    border-radius: 4px;
  }
  @media (max-width: 375px) {
    margin-top: 12px;
  }
  &:last-child {
    margin-bottom: 4px;
  }
  /* -------------------------------------------------------------- */
  /* Additional */
  /* -------------------------------------------------------------- */
  & + .paymentHistoryYearItem {
    margin-top: 20px;
    @media (max-width: 1536px) {
      margin-top: 16px;
    }
    @media (max-width: 1280px) {
      margin-top: 12px;
    }
    @media (max-width: 375px) {
      margin-top: 8px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Top */
  /* -------------------------------------------------------------- */
  > div:first-child {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    min-height: 80px;
    padding: 0 32px;
    position: relative;
    width: auto;
    @media (max-width: 1280px) {
      min-height: 72px;
      padding: 0 28px;
    }
    @media (max-width: 1024px) {
      min-height: 64px;
      padding: 0 24px;
    }
    @media (max-width: 640px) {
      min-height: 63px;
    }
    @media (max-width: 460px) {
      min-height: 62px;
    }
    @media (max-width: 375px) {
      min-height: 56px;
      padding: 0 16px;
    }
    /* ------------------------------------------------------------ */
    /* Date */
    /* ------------------------------------------------------------ */
    > h4 {
      color: $brandBlackGrape;
      font-size: 20px;
      font-weight: $semibold;
      letter-spacing: normal;
      line-height: 28px;
      @include user-select;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 27px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 26px;
      }
      @media (max-width: 640px) {
        font-size: 17px;
        line-height: 25px;
      }
      @media (max-width: 375px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    /* ------------------------------------------------------------ */
    /* Button */
    /* ------------------------------------------------------------ */
    > button {
      background-color: $brandBlackGrape;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 28px;
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      @include no-user-select;
      @media (max-width: 1280px) {
        right: 28px;
      }
      @media (max-width: 1024px) {
        right: 24px;
      }
      @media (max-width: 375px) {
        height: 24px;
        right: 16px;
        width: 24px;
      }
      &:focus::after {
        border-color: $brandBlackGrape;
      }
      /* ---------------------------------------------------------- */
      /* Focus Ring */
      /* ---------------------------------------------------------- */
      &::after {
        border-color: transparent;
        border-radius: 50%;
        border-style: dashed;
        border-width: 2px;
        box-sizing: border-box;
        content: '';
        height: calc(100% + 12px);
        left: -6px;
        pointer-events: none;
        position: absolute;
        top: -6px;
        width: calc(100% + 12px);
        @include fast-transition($props: 'border-color');
      }
      /* ---------------------------------------------------------- */
      /* SVG */
      /* ---------------------------------------------------------- */
      > svg {
        fill: $mono1;
        transform: rotate(-180deg);
        will-change: transform;
        @include fast-transition($mob: true, $props: 'transform');
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Bottom */
  /* -------------------------------------------------------------- */
  > div:last-child {
    margin-top: -2px;
    opacity: 1;
    overflow: hidden;
    transform: scaleY(1);
    transform-origin: 0 0;
    will-change: height, opacity, transform;
    @include medium-transition(
      $firefox: false,
      $mob: true,
      $props: 'height, opacity, transform'
    );
    @media (max-width: 1280px) {
      margin-top: -3px;
    }
    @media (max-width: 375px) {
      margin-top: -4px;
    }
    /* ------------------------------------------------------------ */
    /* Inner */
    /* ------------------------------------------------------------ */
    > div {
      display: block;
      padding: 0 32px 32px 32px;
      @media (max-width: 1280px) {
        padding: 0 28px 28px 28px;
      }
      @media (max-width: 1024px) {
        padding: 0 24px 24px 24px;
      }
      @media (max-width: 375px) {
        padding: 0 16px 16px 16px;
      }
      /* ---------------------------------------------------------- */
      /* Bottom */
      /* ---------------------------------------------------------- */
      > table {
        border-collapse: collapse;
        margin-bottom: 26px;
        width: 100%;
        @include no-user-select;
        @media (max-width: 1280px) {
          margin-bottom: 24px;
        }
        @media (max-width: 640px) {
          margin-bottom: 20px;
        }
        @media (max-width: 375px) {
          margin-bottom: 16px;
        }
        > tbody > tr > td,
        > tfoot > tr > th {
          color: $brandBlackGrape;
          font-size: 18px;
          letter-spacing: normal;
          line-height: 26px;
          padding: 4px 0 9px 0;
          @include user-select;
          @media (max-width: 1536px) {
            font-size: 17px;
            line-height: 25px;
            padding-bottom: 5px;
          }
          @media (max-width: 1280px) {
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 2px;
          }
          @media (max-width: 375px) {
            font-size: 15px;
            line-height: 23px;
            padding-bottom: 0;
          }
        }
        > tbody > tr > td {
          &:last-child {
            white-space: nowrap;
          }
        }
        > tfoot > tr > th {
          border-top: 1px solid $mono2;
          font-weight: $bold;
          &:last-child {
            white-space: nowrap;
          }
        }
        > tbody > tr:first-child > td {
          padding-top: 0;
        }
        > tbody > tr:last-child > td {
          padding-bottom: 14px;
          @media (max-width: 1280px) {
            padding-bottom: 11px;
          }
          @media (max-width: 640px) {
            padding-bottom: 9px;
          }
          @media (max-width: 375px) {
            padding-bottom: 7px;
          }
        }
        > tfoot > tr:last-child > th {
          padding-bottom: 0;
          padding-top: 15px;
          @media (max-width: 1280px) {
            padding-top: 10px;
          }
          @media (max-width: 640px) {
            padding-top: 8px;
          }
          @media (max-width: 375px) {
            padding-top: 6px;
          }
        }
        > tbody > tr > td:first-child,
        > tfoot > tr > th:first-child {
          text-align: left;
        }
        > tbody > tr > td:last-child,
        > tfoot > tr > th:last-child {
          padding-left: 12px;
          text-align: right;
        }
      }
      /* ---------------------------------------------------------- */
      /* Button */
      /* ---------------------------------------------------------- */
      > a {
        align-items: center;
        cursor: pointer;
        display: flex;
        float: right;
        margin-bottom: 20px;
        position: relative;
        @include no-user-select;
        &:focus {
          span {
            color: $brandAquaDark;
            text-decoration: underline;
          }
          svg * {
            fill: $brandAquaDark;
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            span {
              color: $brandAquaDark;
            }
            svg * {
              fill: $brandAquaDark;
            }
          }
          &:active {
            span {
              color: $mono5;
            }
            svg * {
              fill: $mono5;
            }
          }
        }
        :global(.platform-mobile) & {
          &:active {
            span {
              color: $brandAquaDark;
            }
            svg * {
              fill: $brandAquaDark;
            }
          }
        }
        /* -------------------------------------------------------- */
        /* SVG */
        /* -------------------------------------------------------- */
        > svg {
          display: inline-block;
          height: 20px;
          margin: 0 10px 0 0;
          vertical-align: middle;
          width: 20px;
          * {
            fill: $brandBlackGrape;
            @include fast-transition($props: 'fill');
          }
        }
        /* -------------------------------------------------------- */
        /* Text */
        /* -------------------------------------------------------- */
        > span {
          color: $brandBlackGrape;
          display: inline-block;
          font-size: 19px;
          font-weight: $semibold;
          letter-spacing: normal;
          line-height: 27px;
          vertical-align: middle;
          @include fast-transition($props: 'color');
          @media (max-width: 1536px) {
            font-size: 18px;
            line-height: 26px;
          }
          @media (max-width: 1280px) {
            font-size: 17px;
            line-height: 25px;
          }
          @media (max-width: 375px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Closed */
  /* -------------------------------------------------------------- */
  &.closed {
    > div:first-child > button > svg {
      transform: rotate(0deg);
    }
    > div:last-child {
      height: 0;
      opacity: 0;
      transform: scaleY(0.6);
    }
    /* ------------------------------------------------------------ */
    /* Prevent Content Children From Being Focused */
    /* ------------------------------------------------------------ */
    &:not(.animating) > div:last-child > * {
      visibility: hidden;
    }
  }
}
